.raffle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 15%;
  background-color: var(--background-color1);
  z-index: 20;
}

.rafflemain {
  position: relative;
  max-width: 100%;
  background: var(--background-color3);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
.user-cube-balance {
  display: flex;
  align-items: center;
  padding: 2px;
  text-align: center;
  justify-content: center;
  gap: 5px;
}
.user-cube-balance img {
  width: 40px;
}

.user-cube-balance h6 {
  margin: 0;
}

.raffle-form-select {
  padding: 10px;
  border: var(--border2);
  border-radius: 5px;
  background-color: var(--background-color);
  cursor: pointer;
  color: white;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
}

.raffle-box-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  gap: 15px;
}

.raffle-box {
  background: var(--background-color2);
  border-radius: 10px;
  padding: 20px;
  min-width: 300px;
}
.raffle-box img {
  width: 100%;
}
.raffle-reward a {
  font-size: larger;
  margin-left: 10px;
  color: var(--accent-color2);
}

.raffle-description {
  border-bottom: 1px solid var(--background-color4);
}
.raffle-timer-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.raffle-timer-container span {
  color: var(--accent-color4);
}
.raffle-entries img {
  width: 40px;
}

.raffle-entries-button {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.raffle-entries-pick {
  display: flex;
  align-content: center;

  text-align: center;
}

.raffle-entries-button img {
  width: 40px;
}

.raffle-not-connected {
  display: inline;
}
/* Remove spinner arrows from number inputs */
input[type="number"].entries-input::-webkit-inner-spin-button,
input[type="number"].entries-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].entries-input {
  -moz-appearance: textfield; /* For Firefox */
}

.entries-input {
  color: white;
  background: var(--background-color5);
  border: none;
  width: 100px;
  margin: 0;
  text-align: center;
}

.user-add-icon {
  font-size: xx-large;
  margin-left: 15px;
  color: var(--accent-color);
}

.user-add-icon:hover {
  color: var(--accent-color2);
}

.user-add-icon:active {
  padding: 2px;
}

.raffle-popover {
  position: absolute;
  z-index: 100;
  right: 35px;
  top: 5px;
}

@media (max-width: 760px) {
  .raffle-box-container {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .raffle-box {
    width: 100%;
  }
}
