.shopmain {
  position: relative;
  background: linear-gradient(rgba(10, 19, 28, 0.3), rgba(44, 63, 80, 0.3));
  background: var(--background-color3);
  border-radius: 10px;
  margin: auto;
  width: 100%;
  padding: 15px;
}

.shopmain .leftpart {
  max-width: 95%;
  width: 30%;
}
.shopmain .rightpart {
  max-width: 95%;
  width: 70%;
}

.shopmain h2 {
  writing-mode: vertical-rl;
  position: absolute;
  left: -50px;
  margin-top: 10px;
}

.maincontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  gap: 30px;
}

.leftpart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trait-shop-select {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: var(--background-color6);
}

.traitselector {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.basictraits {
  position: relative;
  padding: 10px;

  margin-top: 10px;
}

.marvin3 {
  width: 2000px;
}

.imagestretch {
  width: 100%; /* Ensure the image container takes the full width */
  height: 100%; /* Ensure the image container takes the full height */
  justify-content: center;
}

.specialtraits {
  position: relative;
  padding: 10px;
}

.specialtraits h3 {
  writing-mode: vertical-rl;
  position: absolute;
  right: -35px;
  margin-top: 0px;
}

.specialtraits img {
  width: 100%;
  height: auto;
}

.coin-wrap {
  padding: 10px 0;
  display: flex;
}

.specialtraits .coin {
  margin-right: 10px;
}

.specialwarning {
  width: 80%;
  margin: auto;
}
.specialwarning h5 {
  color: crimson;
}
.specialwarning p {
  font-size: 1em;
}

/* 
TRAITS INSIDE THE TRAIT PICKER */

.trait {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 5px;
  margin-bottom: 15px;
}

.traititem {
  position: relative;
  width: calc(100% / 5 - 5px);
  border-radius: 5px;
  background: var(--background-color6);
}

.traititem h5 {
  font-size: 16px;
  padding: 5px;
  margin: 0;
  text-align: center;
}

.traititem:hover {
  background-color: rgb(157, 246, 255, 0.5);
  cursor: pointer;
}

.traititem.selected {
  background-color: var(--accent-color2);
}

.basictraits img {
  width: 100%;
  padding: 3px;
}

.specialtraits img {
  width: 100%;
  padding: 3px;
}
.trait-type-icon {
  position: absolute;
  top: 4px;
  left: 4px;
  color: var(--accent-color3);
  background: var(--background-color2);
  padding: 4px;
  border-radius: 5px;
  padding-top: 0px;
}
.rarity-box {
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 2px;
  top: 4px;
  right: 4px;
  color: var(--accent-color3);
  background: var(--background-color2);
  padding: 4px;
  border-radius: 5px;
}

.rarity-1 {
  color: #ff091a;
}

.rarity-2 {
  color: var(--accent-color4);
}

.rarity-3 {
  color: #3acbff;
}

.rarity-4 {
  color: #00ff9f;
}

.season-change-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 5px;
}
.season-change-btn > svg {
  font-size: 1.5em;
}

.basic-trait-filter {
  position: absolute;
  width: 10%;
  margin: auto;
  top: 10px;
  left: 15px;
}
.season-button {
  position: absolute;
  margin: auto;
  top: 10px;
  right: 15px;
}

.shopcost {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  background: var(--background-color6);
}
.costmobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.trait-priceshow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120%;
  margin: auto;
}
.trait-priceshow img {
  max-width: 30%;
  height: auto;
}

.trait-priceshow h4 {
  margin: auto;
}

.costmobile button {
  width: 300px;
}

.shopcost .marketwrapper {
  border-top: var(--border2);
  padding: 5px;
}

.shopcost .marketwrapper img {
  width: 50%;
  border: var(--border1);
  border-radius: 5px;
}
.usd > svg {
  font-size: xx-large;
}

.trait-trinket-cost {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.trait-trinket-cost img {
  border-radius: 5px;
  width: 15%;
}

.trait-requirements {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: var(--border2);
}

.trait-requirements h5 {
  color: var(--accent-color2);
}

.description {
  background: var(--background-color6);
  position: relative;
  border-radius: 2px;
}

.copytoken-wrap {
  text-align: center;
}
.copytoken-wrap h6 {
  color: var(--accent-color);
  font-size: 0.9em;
  margin: 0;
}

.copytokenid {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  font-size: 0.9em;
  border-bottom: var(--border2);
  width: 100%;
  padding: 5px;
  gap: 5px;
}
.copytoken-nodescription {
  padding: 5px;
}

.basic-trait-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.trait-img-download {
  position: absolute;
  display: flex;
  z-index: 1;
  color: var(--accent-color);
  font-size: xx-large;
  border: var(--border1);
  border-radius: 5px;
  background: var(--background-color6);
  margin: auto;
  top: 4px;
  right: 5px;
  padding: 3px;
}
.trinket-modal-content-traitshop {
  text-align: center;
  position: relative;
  display: inline;
  background-color: var(--background-color2);
  padding: 30px;
  border-radius: 10px;
  width: 40%;
}

.modal-body img {
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  object-fit: cover;
}

.modal-footer {
  justify-content: center;
  display: flex;
  gap: 5px;
  font-size: 1.1em;
}

.discord-send-flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: var(--background-color6);
  border-radius: 5px;
  padding: 15px;
  align-content: center;
  margin: auto;
}

.trait-shop-discord {
  font-size: 1.5em;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .maincontainer {
    gap: 20px;
  }
  .shopmain .leftpart {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  .trait-shop-select {
    width: 85%;
  }
  .shopmain .rightpart {
    width: 100%;
  }

  .shopmain .maincontainer {
    gap: 10px;
  }

  .traititem {
    width: calc(100% / 3 - 5px);
  }
  .traitswiper {
    max-width: 600px;
    overflow: hidden;
  }
  .shopcost {
    width: 50%;
  }

  .shopcost h2 {
    visibility: hidden;
  }

  .priceshow {
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 110%;
  }

  .priceshow img {
    max-width: 40%;
    height: auto;
  }

  .costmobile {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0px;
  }
  .trinket-modal-content-traitshop {
    width: 85%;
  }
  .rarity-box {
    font-size: 0.7em;
  }
  .trait-type-icon {
    font-size: 0.9em;
  }
  .trait-img-download {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 1024px) and (max-height: 600px) {
  .trinket-modal-content-traitshop {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .maincontainer {
    gap: 20px;
    padding: 0;
  }
  .trait-shop-select {
    width: 100%;
  }
  .shopmain .leftpart {
    width: 100%;
    max-width: 100%;
  }
  .shopmain .rightpart {
    width: 100%;
    max-width: 100%;
  }
  .trait {
    gap: 5px;
    margin-bottom: 5px;
  }

  .traititem {
    width: calc(100% / 3 - 4px);
  }
  .basic-trait-filter {
    width: 20%;
  }
  .shopcost {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    margin: auto;
    z-index: 10;
    max-width: 100%;
  }

  .costmobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5px;
  }
  .costmobile button {
    margin: 0;
    width: 300px;
  }
  .trait-requirements a {
    font-size: 0.8em;
  }
  .trait-requirements h5 {
    font-size: 1em;
    margin: auto;
  }
  .copytoken-wrap h6 {
    font-size: 0.8em;
    margin-bottom: 2px;
  }
  .copytoken-description {
    font-size: 0.8em;
  }
  .trinket-modal-content-traitshop {
    width: 90%;
    padding: 15px;
  }
  .modal-footer {
    font-size: 1em;
  }
  .usd > svg {
    font-size: x-large;
  }
  .season-change-btn {
    gap: 2px;
  }
  .season-change-btn > svg {
    font-size: 1em;
  }
  .rarity-box {
    font-size: 0.6em;
  }
  .trait-type-icon {
    font-size: 0.7em;
  }
  .trait-img-download {
    font-size: 1.2em;
  }
  .shopcost {
    width: 100%;
  }
}
