@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.routerrelative {
  position: relative;
}

.glowtext {
  color: rgb(157, 246, 255);
}

.SwapImage {
  width: 600px;
  height: auto;
}

.algo,
.fccube,
.usd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 5px;
}

.coinlarge {
  width: 100px;
  height: auto;
}

.buttons {
  display: flex;
  align-items: center;
  padding: 10px;
}

/* CUBE STATION STYLE */

.stationcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 10px;
  margin: 10px;
  background-color: #212529;
}

.coin {
  width: 100%;
  max-width: 35px;
  min-width: 25px;
  height: auto;
  top: 0;
  left: 0;
}

.fullmentalspan span {
  padding-right: 5px;
}
.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: auto;
  border-bottom: 2px solid rgb(157, 246, 255, 1);
  box-shadow: 0px 5px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.balance-group {
  display: flex;
  align-items: center;
}

.cubestationmargin {
  margin-bottom: 50px;
}

.weeklyfarm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-left: 2px solid rgb(157, 246, 255, 1);
  box-shadow: -5px 0px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  padding: 10px;
  margin-left: 30px;
  min-height: 200px;
  margin-bottom: 20px;
}

.weeklyfarm h3 {
  position: absolute;
  writing-mode: vertical-rl;
  left: -40px;
  top: 0px;
}

.claimer {
  position: absolute;
  display: flex;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-left: 2px solid rgb(157, 246, 255, 1);
  box-shadow: -5px 0px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  left: 20px;
  min-width: 100px;
  min-height: 111px;
}

.FCanimated {
  max-width: 60px;
}

.copycubeid {
  position: absolute;
  text-align: center;
  font-size: 0.9em;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-left: 2px solid rgb(157, 246, 255, 1);
  box-shadow: -5px 0px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  max-width: 190px;
  right: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fromtraitsclaimer {
  position: absolute;
  text-align: center;
  display: flex;
  font-size: 0.9em;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-left: 2px solid rgb(157, 246, 255, 1);
  box-shadow: -5px 0px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  max-width: 190px;
  top: 62px;
  right: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dividercubes {
  border-left: 2px solid rgb(157, 246, 255, 0.3);
}

.totalclaimed {
  margin-top: 120px;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-left: 2px solid rgb(157, 246, 255, 1);
  box-shadow: -5px 0px 0px 0px rgb(157, 246, 255, 1);
  border-radius: 5px;
  width: 100%;
  text-align: center;
  margin-left: 10px;
}

.totalclaimed img {
  padding-right: 10px;
}

.claimedresults {
  display: flex;
  align-items: center;
}

.results {
  width: 70%;
}
.lottery {
  align-items: center;
  width: 40%;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
}

.buycointainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buyicons {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2em;
}
.buyicons span {
  margin-inline: 5px;
}

.buypricepicker {
  width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.buypricepicker input {
  text-align: center;
  padding: 10px;
  font-size: larger;
  font-weight: 600;
}

.buypricepicker button {
  padding: 8px;
}

.amounttext {
  width: 100%;
}

.fixed-content {
  position: fixed; /* Fix the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Cover the whole viewport */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  z-index: 100; /* Ensure it's above other elements */
}

.randsymbols {
  position: fixed;
  z-index: 0;
  color: rgb(157, 246, 255, 0.2);
  text-shadow: 0 0 15px rgb(157, 246, 255, 0.5);
  pointer-events: none;
  font-size: 0.9vw;
  overflow: hidden;
}

.custom-toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1em;
  z-index: 9999;
}

.react-toastify__toast {
  background-color: #333;
  color: #fff;
}

.react-toastify__close-button {
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-pagination-progressbar {
  background-color: rgb(237, 28, 171);
}
.mySwiper .swiper-pagination-progressbar.swiper-pagination-progressbar-fill {
  background-color: rgb(157, 246, 255);
}

.btn-success {
  width: 40%;
  animation: tilt-shaking 0.15s infinite;
}

.closeposition {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 1;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.btn-success:hover {
  animation-play-state: paused;
}

.closewindow {
  position: absolute;
  right: 1%;
  top: 1%;
  color: #fff;
  text-decoration: none;
  font-size: 2em;
}

.select-field {
  background-color: var(--background-color);
  color: var(--accent-color);
  width: 100%;
  border: var(--border2);
  border-radius: 5px;
}

@media screen and (max-width: 940px) {
  .logo {
    top: 10px;
    left: auto;
    right: 10px;
    transform: translate(0%, 0%);
  }
}

@media only screen and (max-width: 395px) {
  .copycubeid {
    font-size: 0.8em;
    max-width: 180px;
  }

  .fromtraitsclaimer {
    font-size: 0.8em;
    max-width: 180px;
  }
}
@media only screen and (max-width: 380px) {
  .copycubeid {
    font-size: 0.63em;
    max-width: 165px;
  }

  .fromtraitsclaimer {
    font-size: 0.6em;
    max-width: 165px;
  }
}

@media only screen and (max-width: 360px) {
  .copycubeid {
    font-size: 0.5em;
    max-width: 155px;
  }

  .fromtraitsclaimer {
    font-size: 0.6em;
    max-width: 153px;
  }
}

@media only screen and (max-width: 350px) {
  .copycubeid {
    font-size: 0.3em;
    max-width: 135px;
  }

  .fromtraitsclaimer {
    font-size: 0.6em;
    max-width: 135px;
  }
}
