.footercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.footerlogo {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footerlogo img {
  width: 10%;
  max-width: 200px;
  min-width: 70px;
}

.infoclick {
  display: flex;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  color: var(--accent-color);
}

.blinkingarrow {
  animation: blink 4s infinite;
  width: 100%;
}

.infocontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  padding: 20px;
}

.imeobrta {
  position: absolute;
  color: dimgrey;
  pointer-events: none;
  text-align: center;
  justify-content: center;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.socials {
  display: flex;
  width: 100%;
  gap: 20px;

  justify-content: center;
}
.general {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.general h5 {
  font-size: 100%;
  padding: 5px;
  border-left: 2px solid var(--accent-color);
  color: white;
  text-decoration: none;
}

.socialimg {
  width: 50px;
  height: auto;
  color: var(--accent-color);
}
.contactlogo img {
  max-width: 300px;
}
.contactdescription {
  padding: 40px;
}
.company {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
a {
  text-decoration: none;
}
.thestory {
  max-width: 800px;
  padding: 40px;
}

.thestory h5 {
  color: var(--accent-color);
}

.accordion-body {
  text-align: justify;
}

.aboutbox {
  margin-top: 20px;
  border: solid 1px var(--accent-color);
  padding: 10px;
}

.teambox {
  display: flex;
  flex-wrap: wrap;
}

.member {
  width: calc(100% / 4);
}
.teambox img {
  width: 80%;
}

.termsbox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.termsbox h4 {
  color: var(--accent-color);
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

@media only screen and (max-width: 1280px) {
  .infocontainer {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }
  .member {
    width: calc(100% / 3);
  }
  .thestory {
    max-width: 800px;
    padding: 10px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 720px) {
  .member {
    width: calc(100% / 2);
  }
}
