.trait-accordion-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.trait-accordion {
  position: relative;
  box-sizing: border-box;
  border: var(--border1);
  border-radius: 5px;
  background: var(--accent-color);
  flex-grow: 0.2;
  flex-basis: 0;
  overflow: visible;
  align-items: center;
  justify-content: center;
  transition: flex-grow 0.5s ease;
  margin: 0;
}

.trait-accordion:hover {
  background: var(--accent-color2);
  border: var(--border3);
  transition: background 0.3s ease;
}

.trait-accordion.open {
  flex-grow: 6;
  background: none;
  border: var(--border3);
}

.trait-accordion-header {
  cursor: pointer;
  padding: 10px;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.trait-accordion-header.open {
  cursor: auto;
  height: 30px;
}

.trait-accordion h3 {
  writing-mode: vertical-rl; /* Rotates the heading vertically */
  transform: rotate(180deg); /* Rotates the text within the vertical mode */
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

.trait-accordion.open h3 {
  writing-mode: horizontal-tb; /* Rotates the heading vertically */
  transform: rotate(360deg);
  font-size: 1.5em; /* Rotates the text within the vertical mode */
}

.trait-accordion-content {
  opacity: 0;
  padding: 5px;
  width: 50px;
  max-height: 600px;
}

.trait-accordion-content.open {
  opacity: 1;
  transition: opacity 0.5s ease;
  width: 100%;
  max-height: 100%;
}

@media (max-width: 1440px) {
  .trait-accordion-content {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .trait-accordion-container {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .trait-accordion {
    transition: max-height 0.5s ease;
    max-height: 50px;
  }

  .trait-accordion.open {
    max-height: 2000px;
    transition: max-height 0.5s ease;
  }

  .trait-accordion-header {
    cursor: pointer;
    padding: 5px;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .trait-accordion h3 {
    writing-mode: horizontal-tb;
    transform: rotate(360deg);
    font-size: 1.5em;
  }
}
