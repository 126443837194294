.burncontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 5%;
  padding-bottom: 20%;
  background-color: rgba(25, 8, 32, 0.775);
  z-index: 20;
}

.burnNFT {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 70%;
  background: var(--background-color3);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 1;
}

.comicshelf {
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  padding: 2px;
  background-color: #282c34;
}

.comicshelf img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
}

.comicshelf .selected {
  border: 3px solid hsl(186, 100%, 81%, 0.7);
  border-radius: 5px;
  transition: border 0.3s ease;
}

.juicegrid {
  position: relative;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
}

.juicegriditem {
  position: relative;
  width: calc(100% / 6 - 10px);
  padding: 5px;
  background: var(--background-color6);
}

.juicegriditem img {
  width: 100%;
}

.juicegriditem.selected {
  border: 3px solid hsl(186, 100%, 81%, 0.7);
  border-radius: 5px;
  transition: border 0.3s ease;
}

.burnNFT h2 {
  writing-mode: vertical-rl;
  position: absolute;
  left: -50px;
  top: 0px;
  margin-top: 5px;
}

.cubejuice {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.442);
  border-radius: 5px;
  padding: 3px;
  width: 20%;
  font-size: large;
  left: 5px;
  top: 1px;
}

.juicecontainer {
  border: 1px solid rgb(157, 246, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.marvinimg4 {
  width: 100%;
  max-width: 310px;
}

@media (max-width: 1024px) {
  .burncontainer {
    padding-bottom: 20%;
  }

  .burnNFT {
    max-width: 100%;
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 10%;
  }

  .juicegrid {
    position: relative;
    width: 100%;
  }

  .cubejuice {
    padding: 5px;
    width: 5%;
    min-width: 50px;
  }
  .juicegriditem {
    width: calc(100% / 4 - 10px);
    padding: 5px;
  }
}

@media (max-width: 500px) {
  .juicegriditem {
    width: calc(100% / 2 - 10px);
    padding: 5px;
  }
  .cubejuice {
    background: rgba(0, 0, 0, 0.442);
    padding: 5px;
    width: 5%;
    min-width: 50px;
  }
  .juicecontainer {
    position: fixed;
    flex-direction: row;
    width: 100%;
    bottom: 0px;
    background: var(--background-color6);
    padding: 5px;
  }
}
