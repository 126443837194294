.connectdropdown {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  text-align: center;
  width: 100%; /* Make it take up the full width of the screen */
  max-width: 95%; /* Ensure it doesn't exceed the screen width */
  gap: 20px;
}

.connectbody {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  color: rgb(157, 246, 255);
  background: var(--background-color6);
  border: var(--border3);
  border-radius: 10px;
  margin-right: 80px;
  padding: 15px;
}

.connectbody h2 {
  color: #fff;
  writing-mode: vertical-rl;
  position: absolute;
  left: -40px;
  top: 5px;
}

.connectbody h4 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  color: #fff;
}

.connectbody img {
  margin-right: 5px;
}

.connectbody span {
  margin-left: 5px;
}
.connectgrid {
  width: calc(100% / 2);
  justify-items: center;
  padding-bottom: 10px;
}

.walletinfo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  background: var(--background-color5);
  border: var(--border1);
  border-radius: 10px;
  margin-right: 80px;
}

.walletinfo h2 {
  color: #fff;
  writing-mode: vertical-rl;
  position: absolute;
  left: -40px;
  top: 5px;
}
.walletinfo img {
  width: 80%;
  min-width: 30px;
}

.downloadwallet {
  width: 100%;
}
.WalletSwiper .swiper-slide {
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  justify-content: center;
}

.WalletSwiper .swiper-button-next,
.WalletSwiper .swiper-button-prev {
  color: rgb(157, 246, 255, 1);
}

.dwalletcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WalletSwiper .swiper-pagination {
  position: relative;
  color: #fff;
}

.WalletSwiper .swiper-pagination-bullet {
  background-color: rgb(157, 246, 255, 1);
}

.wtitle {
  max-width: 40%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  color: #fff;
}
.wtext {
  position: relative;
  max-width: 60%;

  color: #fff;
}

.info {
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
  color: #fff;
  padding: 15px;
  background: var(--background-color2);
  border: var(--border2);
  border-radius: 10px;
  margin-right: 80px;
}

.info h2 {
  color: #fff;
  writing-mode: vertical-rl;
  position: absolute;
  left: -40px;
  top: 5px;
}

.navbuttons {
  position: absolute;
  display: flex;
  top: -5px;
  right: 10%;
  gap: 10px;
  z-index: 1;
}
.connectbutton button {
  height: 70px;
}

.cubebutton button {
  height: 90px;
}
@media screen and (max-width: 1024px) {
  .navbuttons {
    top: -1px;
    right: 3%;
    gap: 5px;
  }
  .connectbutton button {
    height: 60px;
  }
  .connectbody {
    margin-right: 0;
    padding: 5px;
    margin-left: 0px;
    margin-bottom: 10px;
    font-size: 0.8em;
  }
  .connectbody h2 {
    left: -25px;
    font-size: 1.3em;
  }
  .connectbody button {
    width: 70px;
    height: auto;
    font-size: 0.8em;
  }

  .walletinfo {
    height: auto;
    margin-right: 0;

    margin-bottom: 10px;
    font-size: 0.8em;
  }
  .walletinfo h2 {
    left: -25px;
    top: 35px;
    font-size: 1.3em;
  }

  .info {
    margin-right: 0;
    font-size: 0.8em;
  }
  .info h2 {
    font-size: 1.3em;
    left: -25px;
    top: 25px;
  }
}

@media screen and (min-width: 1920px) {
  .connectbutton button {
    height: 90px;
  }
}
