.specialscont {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 2%;
  padding-bottom: 10%;
  background-color: rgba(25, 8, 32, 0.775);
  z-index: 20;
}

.specialsmain {
  position: relative;
  max-width: 80%;
  background: linear-gradient(rgba(10, 19, 28, 0.3), rgba(44, 63, 80, 0.3));
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  height: auto; /* Set height to 100% of the viewport height */
  align-self: stretch; /* Stretch to fill the available vertical space */
  z-index: 1;
}

.specialsmain::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

.specialsgrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 400px));
  gap: 10px;
  text-align: center;
}
.specialsmain h2 {
  writing-mode: vertical-rl;
  position: absolute;
  left: -50px;
  top: 0px;
  margin-top: 5px;
}

.stokenframe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5%;
  margin-bottom: 5px;
}

.specialdescription {
  font-size: 100%;
}

.specialtraitbox {
  width: auto;
  height: auto;
  display: block;
  align-content: top;
  justify-content: center;
  text-align: center;
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  border-top: 3px solid rgb(159, 87, 218);
  box-shadow: 0px -10px 0px 0px rgb(159, 87, 218);
  border-radius: 5px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.specialtraitimage {
  max-width: 100%;
}

.specialtraittoken {
  max-width: 15%;
}
.sboxcontent {
  width: 90%;
  align-content: top;
  justify-content: center;
  text-align: center;
}

@media (max-width: 640px) {
  .specialsgrid {
    grid-template-columns: repeat(2, minmax(100px, 250px));
  }

  .specialsmain {
    max-width: 95%;
    padding: 10px;
  }
  .stokenframe h6 {
    font-size: 75%;
  }

  .stokenframe h6 {
    font-size: 75%;
  }
}

@media (min-width: 1024px) {
  .specialsgrid {
    grid-template-columns: repeat(4, minmax(100px, 400px));
  }

  .specialsmain {
    max-width: 95%;
    padding: 10px;
  }
  .stokenframe h6 {
    font-size: 75%;
  }

  .stokenframe h6 {
    font-size: 75%;
  }
}
