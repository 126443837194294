.traitcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.traitbox {
  background-color: #282c34;
  overflow: hidden;
  border: 2px solid;
  font-size: 0.9em;
  padding: 2px;
  border-color: rgb(157, 246, 255, 0.384);
  border-radius: 3px;
  margin: 3px;
  flex: 1;
  width: 100%;
  min-width: fit-content;
}

.group {
  color: #9df6ff;
  font-weight: bold;
}

.Special2 {
  border-color: hsl(186, 100%, 81%, 0.7);
  box-shadow: 0px 0px 0px 2px rgb(157, 246, 255, 1);
  background-color: darkslateblue;
}

@media only screen and (max-width: 1024px) {
  .traitbox {
    font-size: 0.7em;

    margin: 1px;
  }
}
