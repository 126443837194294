@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Train+One&display=swap");

#home-container {
  width: 100%;
}

#banner-container {
  width: 100%;

  margin-bottom: 20px;
  border-bottom: 2px solid rgb(157, 246, 255, 0.3);
  box-shadow: -10px 0px 0px 0px rgb(157, 246, 255, 1);
}
#banner-container img {
  max-width: 100%;
  min-width: 860px;
}

#mid-container,
#right-container {
  width: calc(33% - 20px);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: hsl(187, 24%, 93%);
  margin: 10px;
  padding: 30px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.2) 0%,
    rgba(44, 63, 80, 0.6) 100%
  );
  box-sizing: border-box;
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
#left-container {
  width: calc(33% - 20px);
  height: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: hsl(187, 24%, 93%);
  margin: 10px;
  padding: 30px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.4) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  box-sizing: border-box;
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  display: inline-block;
  vertical-align: top;
  position: relative; /* Required for absolute positioning within */

  /* Background with image and overlay gradient */
}
.asci1 {
  position: absolute;
  writing-mode: vertical-rl;
  text-align: left;
  color: rgb(157, 246, 255, 0.7);
  left: 5px;
  top: 5px;
  text-shadow: 0 0 15px rgb(157, 246, 255, 0.8);
  pointer-events: none;
  font-size: 0.7vw;
}
.asci2 {
  position: absolute;
  writing-mode: vertical-rl;
  text-align: right;
  color: rgb(157, 246, 255, 0.7);
  left: 5px;
  bottom: 5px;
  text-shadow: 0 0 15px rgb(157, 246, 255, 0.8);
  pointer-events: none;
  font-size: 0.7vw;
}

.asci3 {
  position: absolute;
  text-align: right;
  color: rgb(157, 246, 255, 0.7);
  right: 10px;
  top: 10px;
  pointer-events: none;
  text-shadow: 0 0 15px rgb(157, 246, 255, 0.8);
  border: solid 1px rgb(157, 246, 255, 0.7);
  border-radius: 1px;
  font-size: 0.7vw;
}

.asci4 {
  position: absolute;
  text-align: right;
  color: rgb(157, 246, 255, 0.7);
  right: 5px;
  bottom: 0px;
  text-shadow: 0 0 15px rgb(157, 246, 255, 0.8);
  pointer-events: none;
  font-size: 0.7vw;
}

.mentalAF-container {
  position: relative;
  width: 70%;
  background: var(--background-color6);
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 20px;
}
.mental-af-horizontal {
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border-bottom: var(--border2);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.mental-af-horizontal .swapperimage {
  width: 100%;
}
.mental-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: large;
}

.MentalAF-collection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  max-height: 630px;
  width: 100%;
  overflow: auto;
  background: var(--background-color2);
  border-radius: 10px;
  padding: 7px;
}
.MAF-collection {
  position: relative;
  width: calc(100% / 4 - 6px);
  padding: 0px;
}

.MAF-collection img {
  width: 100%;
}

.MentalAF-collection::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.MentalAF-collection::-webkit-scrollbar-thumb {
  background-color: #9df6ff; /* Set the color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

.MentalAF-collection::-webkit-scrollbar-track {
  background-color: #282c34; /* Set the color of the track */
}

.MAF-pagination .paginationButtonsWrap {
  justify-content: center;
}
.mentalAF-container h2 {
  color: var(--accent-color);
}
.mentalAF-container h3 {
  color: var(--accent-color2);
  border-bottom: var(--border2);
  padding-bottom: 10px;
}
.mentalAF-container img {
  width: 100%;
  margin: auto;
}

.slidercontent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set height of the slide content container */
}

.mentalaf {
  display: block;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: 2%;
  padding-bottom: 15%;
  background-color: rgba(25, 8, 32, 0.775);
  z-index: 20;
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
  .mentalAF-container {
    width: 80%;
  }
  .mentalAF-container h3 {
    font-size: 1em;
  }
  .mentalAF-container img {
    width: 100%;
  }
  .mental-description {
    font-size: 0.8em;
  }
  .MentalAF-collection {
    gap: 5px;
    max-height: 400px;
  }
  .MAF-collection {
    position: relative;
    width: calc(100% / 4 - 6px);
    padding: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .mentalAF-container {
    width: 90%;
  }
  .mental-af-horizontal {
    display: inline;
    align-items: center;
    justify-content: center;
  }

  .mental-description {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: normal;
  }
  .winner-img {
    margin: auto;
    width: 50%;
    margin-bottom: 15px;
  }
  .MentalAF-collection {
    gap: 5px;
    max-height: 430px;
  }
  .MAF-collection {
    position: relative;
    width: calc(100% / 4 - 6px);
    padding: 0px;
  }
  .winner-text {
    border-top: var(--border2);
    padding-top: 5px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .mentalAF-container {
    width: 65%;
    overflow: auto;
  }
  .mentalAF-container h3 {
    font-size: 1em;
  }
  .mentalAF-container img {
    width: 100%;
  }
  .mentalAF-container p {
    font-size: 1em;
  }
  .mental-af-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mental-af-horizontal .swapperimage {
    width: 100%;
  }
  .MentalAF-collection {
    gap: 5px;
    max-height: 300px;
    width: 100%;
    overflow: auto;
  }
  .mental-description {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .winner-img {
    margin: auto;
    width: 100%;
    margin-bottom: 15px;
  }
  .MAF-collection {
    position: relative;
    width: calc(100% / 3 - 6px);
    padding: 0px;
  }
  .winner-text {
    border-top: none;
    padding-top: 5px;
  }
}

@media screen and (max-width: 940px) {
  #left-container,
  #right-container,
  #mid-container {
    width: calc(100% - 40px);
    display: block;
    margin: 20px auto;
  }
}
@media screen and (max-width: 768px) {
  .mentalAF-container {
    width: 95%;
    padding: 10px;
  }
  .mental-af-horizontal {
    display: inline;
    align-items: center;
    justify-content: center;
  }
  .mental-af-horizontal .swapperimage {
    width: 100%;
  }
  .mental-description {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: normal;
  }
  .winner-img {
    margin: auto;
    width: 70%;
    margin-bottom: 15px;
  }
  .MentalAF-collection {
    gap: 5px;
    max-height: 170px;
  }
  .MAF-collection {
    position: relative;
    width: calc(100% / 4 - 6px);
    padding: 0px;
  }
}

@media screen and (max-width: 376px) {
  .winner-img {
    margin: auto;
    width: 70%;
    margin-bottom: 15px;
  }
  .MentalAF-collection {
    gap: 5px;
    max-height: 160px;
  }
  .MAF-collection {
    position: relative;
    width: calc(100% / 4 - 6px);
    padding: 0px;
  }
  .winner-img {
    margin: auto;
    width: 60%;
    margin-bottom: 15px;
  }
}
