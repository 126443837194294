@import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap");

.navcontainer {
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navloading {
  display: flex;
  flex-direction: column;
  padding: 10%;
  text-align: center;
}
.navloading p {
  padding-top: 50px;
  margin-bottom: 0;
}

.marvincontainer {
  display: block;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  /*  height: auto; */
  padding-top: 2%;
  padding-bottom: clamp(10%, 35%, 400px);
  background-color: rgba(25, 8, 32, 0.775);
  z-index: 20;
}

.responsive-icon {
  width: 100%;
  min-width: 30px;
  min-height: 30px;
}

.navbar {
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  height: 100%;
}

.responsive .logo {
  width: 70%;
  max-width: 950px;
  min-width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.logosmall {
  width: 50%;
  max-width: 700px;
  min-width: 300px;
  height: auto;
  position: absolute;
  left: 5%;
  top: 1%;
  overflow: hidden;
}

/* Floating buttons container */
.floating-buttons {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 0px;
}

.floating-buttons h5 {
  font-size: 1.5em;
}

.relative-position {
  position: relative;
  width: 100%; /* Ensure it occupies the entire width of its parent */
  height: auto; /* Allow the height to adjust based on the image */
}
.sitemain {
  width: 100%;
  height: auto; /* Allow the height to adjust based on the image */
}

/* ALL THE ELEMENTS INSIDE THE NAVIGATION IMAGE  */
.fullmentalcall img {
  width: 11%;
}
.fullmentalcall {
  font-size: 90%;
}
.flats {
  position: absolute;
  width: 14.2%;
  top: 0%;
  left: 78.32%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
  opacity: 2%;
}

.flats:hover {
  filter: drop-shadow(0px 0px 20px rgb(125, 196, 239, 0.2));
  opacity: 100%;
  cursor: pointer;
}
.af {
  position: absolute;
  width: 7.65%;
  top: 53.15%;
  left: 77.98%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 2;
  opacity: 0%;
}
.chair {
  position: absolute;
  width: 6.9%;
  top: 76.45%;
  left: 85.58%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 3;
  opacity: 100%;
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.7))
    drop-shadow(0 0 20px rgba(255, 215, 0, 0.5));
  animation: sparkle 1.5s infinite ease-in-out;
}

.chair:hover {
  cursor: pointer;
}

.afcar {
  position: absolute;
  width: 13%;
  top: 68%;
  left: 21.98%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 2;
}

.afcar:hover {
  cursor: pointer;
}

@keyframes sparkle {
  0%,
  100% {
    filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.7))
      drop-shadow(0 0 20px rgba(255, 215, 0, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 15px rgba(255, 255, 0, 1))
      drop-shadow(0 0 30px rgba(255, 223, 0, 0.8));
  }
}

.popover-christmas {
  position: absolute;
  padding: 5px;
  background: white;
  border-radius: 20px;
  color: black;
  width: 150px;
  top: 70.45%;
  left: 85.58%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 3;
  font-size: 2em;
  text-align: center;
}

.popover-christmas2 {
  position: absolute;
  padding: 5px;
  background: white;
  border-radius: 20px;
  color: black;
  width: 150px;
  top: 70.45%;
  left: 25.58%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 3;
  font-size: 1.5em;
  text-align: center;
}
.af:hover {
  opacity: 100%;
  cursor: pointer;
}

.catitout {
  position: absolute;
  width: 9.64%;
  top: 49.5%;
  left: 20.75%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
}

.catitout:hover {
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
}

.labs {
  position: absolute;
  width: 11.24%;
  top: 16.71%;
  left: 62.55%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 3;
}

/* .labs:hover {
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
} */
.cubeshop {
  position: absolute;
  width: 8.8%;
  top: 38.7%;
  left: 37.7%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 2;
}
.cubeshop1 {
  position: absolute;
  width: 8.8%;
  top: 38.7%;
  left: 37.7%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
  animation: stroboscope 3s linear infinite;
  filter: blur(1);
}

.cubeshop:hover {
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
  cursor: pointer;
}

.traitshop {
  position: absolute;
  width: 12.1%;
  top: 60.5%;
  left: 59.2%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
  filter: grayscale(0.2);
}

.traitshop:hover {
  filter: drop-shadow(0px 0px 20px pink);
  cursor: pointer;
}
.colector {
  position: absolute;
  width: 11.24%;
  top: 64.11%;
  left: 47.94%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
  filter: grayscale(0.3);
}

/* .colector:hover {
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
} */

.character {
  position: absolute;
  width: 3.5%;
  top: 70.36%;
  left: 42%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 2;
  transform: scaleX(-1);
}
.kiosk {
  position: absolute;
  width: 22.64%;
  top: 60.46%;
  left: 42.5%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
  filter: grayscale(0.1);
}

.kiosk:hover {
  cursor: pointer;
  opacity: 100%;
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
}

.asylum {
  position: absolute;
  width: 10.12%;
  top: 60.1%;
  left: 88.5%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 5;
  opacity: 2%;
}

.asylum:hover {
  cursor: pointer;
  opacity: 100%;
  animation: stroboscope 1.5s linear infinite;
}

.orange {
  position: absolute;
  width: 10.14%;
  top: 37.11%;
  left: 45.24%;
  transform: translateX(-50%); /* Center the image horizontally */
  z-index: 1;
}

.orange:hover {
  filter: drop-shadow(0px 0px 20px rgba(175, 223, 233, 0.7));
}
/* POPUPS!!! */
.popups h6 {
  font-size: clamp(0.8rem, 0.9vw, 1.1rem);
}
.popups h4 {
  font-size: clamp(1rem, 1.2vw, 1.5rem);
}

.popup {
  position: absolute;
  display: block;
  top: 72%;
  left: 55%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
.popup.show {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.popup::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

.popup2 {
  position: absolute;
  display: block;
  top: 39%;
  left: 25%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup2::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

.popup4 {
  position: absolute;
  display: block;
  top: 70%;
  left: 30%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup4::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

.popup3 {
  position: absolute;
  display: block;
  top: 58%;
  left: 72%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup3::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

.popup5 {
  position: absolute;
  display: block;
  top: 48%;
  left: 42%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup5::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}
.popup6 {
  position: absolute;
  display: block;
  top: 18%;
  left: 75%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup6::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}
.popup7 {
  position: absolute;
  display: block;
  top: 70%;
  left: 15%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup7::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}
.popup8 {
  position: absolute;
  display: block;
  top: 22%;
  left: 58%;
  width: 10%;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.6) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
  border: 6px solid rgb(157, 246, 255, 0.9);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1) inset;
  border-radius: 1px;
  z-index: 10;
}

.popup8::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1); /* Adjust glow color and thickness as needed */
  border-radius: inherit;
  z-index: -1;
}

/* Individual buttons */
.floating-buttons .nav-link {
  position: relative;
  display: inline-block;
  width: 200px;
  height: auto;
  color: hsl(187, 24%, 93%);
  border-top: none;
  border-bottom: 1px solid rgb(157, 246, 255, 0.3);
  border-left: none;
  border-right: none;
  background: rgb(157, 246, 255, 0);
  cursor: pointer;
  transition: border-bottom 0.3s ease-in-out;
  overflow: hidden;
  text-align: center;
}

.floating-buttons .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: rgb(157, 246, 255, 1);
  border: rgb(157, 246, 255, 1);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1);
  transition: width 0.2s ease-in-out; /* Adjust the transition duration as needed */
}

.floating-buttons .nav-link.active::after {
  width: 100%;
}

.floating-buttons .nav-link:hover {
  font-size: 110%;
}

.mainswiper {
  position: relative;
  width: 100%;
  height: 100%;
}

.mainswiper .swiper-scrollbar-drag {
  background-color: rgb(157, 246, 255, 1);
}

.mainimgswipe {
  max-width: 100%;
  max-height: auto;
  object-fit: scale-down;
}

.arrow-left,
.arrow-right {
  position: absolute;
  display: flex;
  top: auto;
  bottom: 30px;
  z-index: 10;
}

.arrow-left {
  left: 10px;
}
.arrow-left svg,
.arrow-right svg {
  color: aqua;
  width: 2rem;
  height: 2rem;
}

.arrow-right {
  right: 10px;
}

.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.scrolltag {
  position: absolute;
  display: flex;
  align-items: center;
  top: auto;
  bottom: 20px;
  z-index: 10;
  color: rgb(157, 246, 255, 1);
}

.tilted {
  position: absolute;
  top: 44%;
  left: 48.3%;
  width: 16.7%;
  height: 20%;
  perspective: 900px;
}

.mentalpanel {
  position: absolute;
  width: 8.9%;
  top: 19.3%;
  left: 48.25%;
  height: auto;
  perspective: 300px;
  transform: rotateY(0deg) skewX(6deg) rotateZ(6deg) scale(1);
  box-shadow: 0px 0px 20px 0px rgb(157, 246, 255, 1);
  animation: glitch 6.5s infinite;
  z-index: 5;
}

.mentalpanel img {
  width: 100%;
}
.mainimgswipe {
  filter: grayscale(0.3);
}

.bannerslider {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  transform-origin: left;
  transform: rotateY(17.5deg) skewX(15.5deg) rotateZ(16.5deg) scale(1);
  box-shadow: 0px 0px 40px 0px rgb(157, 246, 255, 1);
}
.bannerslider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* //ANIMATIONS// */

.hero {
  position: absolute;
  width: 1.3%;
  top: 44.3%;
  left: 25.2%;

  z-index: 1;
}

.sewer {
  position: absolute;
  width: 5%;
  top: 78.3%;
  left: 73.5%;

  z-index: 1;
}
.sewer2 {
  position: absolute;
  width: 4%;
  top: 77.5%;
  left: 23.9%;
  transform: rotateY(180deg);
  z-index: 1;
}

.smoke {
  position: absolute;
  width: 9%;
  top: 11.9%;
  left: 87.2%;
  transform: rotateY(180deg);
  filter: grayscale(0.4);
  z-index: 1;
}

.traka {
  position: absolute;
  width: 3.4%;
  top: 76.1%;
  left: 41.3%;
  z-index: 1;
  filter: grayscale(0.4);
}

@keyframes glitch {
  0%,
  35% {
    opacity: 1; /* Image is visible */
  }
  35.5%,
  36% {
    opacity: 0; /* Image disappears */
  }
  36.5%,
  69% {
    opacity: 1; /* Image is visible */
  }
  70%,
  70.5% {
    opacity: 0; /* Image disappears */
  }
  71%,
  100% {
    opacity: 1; /* Image is visible */
  }
}

@keyframes glitch2 {
  0% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Invisible */
  }
}
@keyframes stroboscope {
  0%,
  60%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50%,
  70% {
    opacity: 0; /* Invisible */
  }
}

.scrolling-text {
  position: absolute;
  font-size: 2.9em;
  color: greenyellow;
  font-family: "DotGothic16", sans-serif;
  font-weight: 800;
  font-size: 1.8vw;
  z-index: 10;
  width: 9%;
  top: 22.5%;
  left: 58.4%;
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent text from wrapping */
  transform: rotateY(35deg) skewX(35deg) rotateZ(35deg) scale(1);
  pointer-events: none;
}

.scrolling-text p {
  display: inline-block;
  animation: scrollText 10s linear infinite;
}

.scrolling-text2 {
  position: absolute;

  font-size: 2.4em;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 1.8vw;
  z-index: 10;
  width: 3.4%;
  top: 56.2%;
  left: 36.5%;
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent text from wrapping */
  transform: rotateY(0deg) skewX(-35deg) rotateZ(-35deg) scale(1);
  pointer-events: none;
}

.scrolling-text2 p {
  display: inline-block;
  animation: scrollText2 8s linear infinite;
}

.scrolling-text3 {
  position: absolute;

  font-size: 2.4em;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 1.8vw;
  z-index: 10;
  width: 2.3%;
  top: 59.8%;
  left: 37%;
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent text from wrapping */
  transform: rotateY(0deg) skewX(-55deg) rotateZ(-55deg) scale(1);
  pointer-events: none;
}

.scrolling-text3 p {
  display: inline-block;
  animation: scrollText 8s linear infinite;
}
@keyframes scrollText {
  0% {
    transform: translateX(-100%); /* Start from the right */
  }
  100% {
    transform: translateX(100%); /* Go to the left */
  }
}
@keyframes scrollText2 {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Go to the left */
  }
}

.news {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: rgb(34, 9, 44);
}
.ripperspective {
  position: absolute;
  top: 52.8%;
  left: 1%;
  width: 7.45%;
  height: auto;
  perspective: 500px;
}
.ripvideo {
  transform: rotateY(12deg) skewX(10deg) rotateZ(10deg) scale(1);
  pointer-events: none;
}
.ripvideo img {
  width: 100%;
}

.twittperspective {
  position: absolute;

  top: 42%;
  left: 3%;
  width: 15.5%;
  font-size: 0.9vw;
  perspective: 500px;
}
.twitembedd {
  transform: rotateY(20deg) skewX(21deg) rotateZ(21deg) scale(1);
}

.userperspective {
  position: absolute;
  top: 22%;
  left: 34.3%;
  width: 13.1%;
  perspective-origin: right bottom;
  perspective: 250px;
}
.users {
  transform: rotateY(-4deg) skewX(-15deg) rotateZ(-15deg) scale(1);
}
.users img {
  width: 100%;
  animation: glitch2 2.6s steps(10) infinite;
}

.users p {
  font-size: 0.7vw;
}
.users h4 {
  font-size: 0.8vw;
  text-align: center;
}

@keyframes glitch2 {
  0% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.8;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.mentalclose {
  position: absolute;
  top: 15px;
  right: -45%;
  width: 100%;
  color: hsl(187, 24%, 93%);
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  text-decoration: none;
}

.mentalclose :hover {
  font-size: 2.2em;
}

.marvin-landing {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.marvinclose {
  text-decoration: none;
  color: #ffffff;
  margin-right: 10px;
  padding: 2px;
  border: solid 1px rgb(157, 246, 255, 0.9);
  border-radius: 5px;
}

.marvinclose :hover {
  color: rgb(157, 246, 255, 1);
}

/* Chat bubble container */
.chat-container {
  position: absolute;
  top: 68.36%;
  left: 47%;
  transform: translateX(-50%);
  border-radius: 10px;
  z-index: 2;
}
.chat-bubble {
  position: relative;
  background-color: #fffefe;
  text-align: center;
  color: black;
  padding: 3px;
  max-width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Arrow for chat bubble */
.chat-bubble::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Position the arrow slightly outside the bubble */
  left: 10px; /* Align the arrow to the left edge inside the bubble */
  border-width: 10px;
  transform: rotate(90deg);
  border-style: solid;
  border-color: transparent transparent #f1f0f0 transparent;
}

/* Show chat bubble */
.chat-bubble.show {
  display: block;
  opacity: 1;
}

@keyframes candyshake {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  25% {
    transform: translateX(-2px) rotate(-5deg);
  }
  50% {
    transform: translateX(2px) rotate(5deg);
  }
  75% {
    transform: translateX(-2px) rotate(-5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

@media (max-width: 1024px) {
  .floating-buttons {
    margin-top: 0px;
  }
  .floating-buttons h5 {
    margin-top: 0px;
    font-size: 1.2em;
  }

  .traitshop {
    width: 36%;
    top: 60.7%;
    left: 78%;
    transform: translateX(-50%); /* Center the image horizontally */
    z-index: 1;
    filter: grayscale(0.3);
  }
  .orange {
    width: 30%;
    top: 37%;
    left: 35.5%;
  }
  .cubeshop {
    width: 28%;
    top: 38.4%;
    left: 12.4%;
  }
  .mentalpanel {
    width: 26.8%;
    top: 19.5%;
    left: 45.1%;
  }
  .labs {
    width: 35.24%;
    top: 16.71%;
    left: 88.5%;
  }
  .kiosk {
    width: 67.5%;
    top: 60.6%;
    left: 29.7%;
  }
  .character {
    width: 10.5%;
    top: 71%;
    left: 27%;
  }
  .chat-container {
    top: 63.36%;
    left: 44%;
    font-size: small;
  }
  .colector {
    width: 34%;
    top: 63.75%;
    left: 43.8%;
  }
  .af {
    width: 23%;
    top: 53.1%;
    left: 33.88%;
  }
  .asylum {
    width: 30.35%;
    top: 60.2%;
    left: 65.4%;
  }
  .flats {
    width: 42.6%;
    top: 0%;
    left: 34.86%;
  }
  .catitout {
    width: 28%;
    top: 50.4%;
    left: 61.94%;
  }
  .hero {
    width: 4%;
    top: 44.27%;
    left: 76.2%;
  }

  .sewer {
    width: 15%;
    top: 78.3%;
    left: 19.2%;
  }
  .sewer2 {
    width: 12%;
    top: 77.5%;
    left: 68.9%;
  }

  .smoke {
    width: 27%;
    top: 11.8%;
    left: 61%;
  }

  .traka {
    width: 10.4%;
    top: 76.1%;
    left: 23.6%;
    z-index: 2;
  }

  .ripperspective {
    top: 52.7%;
    left: 2.4%;
    width: 22.2%;
  }
  .tilted {
    top: 44%;
    left: 45%;
    width: 50%;
    height: 20%;
    perspective: 900px;
  }
  .scrolling-text {
    font-weight: 800;
    font-size: 4.8vw;
    width: 45%;
    top: 25.7%;
    left: 76%;
  }
  .scrolling-text2 {
    font-weight: 800;
    font-size: 4.8vw;
    width: 10.5%;
    top: 56.5%;
    left: 9%;
  }
  .scrolling-text3 {
    font-weight: 800;
    font-size: 4.8vw;
    width: 7.8%;
    top: 60%;
    left: 10%;
  }
  .userperspective {
    top: 21.6%;
    left: 5.5%;
    width: 34%;
  }
  .fullmentalcall img {
    width: 5%;
  }
  .fullmentalcall {
    font-size: 140%;
  }
  .popups h6 {
    font-weight: 400;
  }
  .popup {
    width: 20%;
    top: 75%;
    left: 55%;
  }
  .popup2 {
    width: 20%;
    top: 20%;
    left: 5%;
  }
  .popup3 {
    width: 20%;
    top: 60%;
    left: 35%;
  }
  .popup4 {
    width: 20%;
    top: 75%;
    left: 5%;
  }
  .popu5 {
    top: 70%;
    left: 45%;
  }
  .popup6 {
    width: 20%;
    top: 15%;
    left: 25%;
  }
  .popup7 {
    width: 20%;
    top: 30%;
    left: 35%;
  }
  .users h4 {
    font-size: 3vw;
  }
  .userperspective {
    top: 22%;
    left: 3.2%;
    width: 39%;
  }
  .candy-cane {
    position: absolute;
    right: 5px;
    bottom: 10px;
    width: 9%;
    margin: auto;
    z-index: 1;
    animation: candyshake 0.4s ease-in-out infinite;
  }
  .chair {
    width: 20.9%;
    top: 76.45%;
    left: 56.58%;
    opacity: 100%;
    filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.7))
      drop-shadow(0 0 20px rgba(255, 215, 0, 0.5));
    animation: sparkle 1.5s infinite ease-in-out;
  }
  .popover-christmas {
    background: white;
    border-radius: 20px;
    color: black;
    width: 150px;
    top: 70.45%;
    left: 55.58%;
    font-size: 2em;
  }
  .afcar {
    width: 40%;
    top: 68%;
    left: 65.98%;
  }
  .popover-christmas2 {
    width: 150px;
    top: 70.45%;
    left: 65.58%;
    font-size: 1.7em;
  }
}

@media (max-width: 500px) {
  .fullmentalcall {
    font-size: 60%;
  }
  .floating-buttons h5 {
    margin-top: 0px;
    font-size: 0.8em;
  }
  .popover-christmas {
    font-size: 1.3em;
  }
  .popover-christmas2 {
    font-size: 1.3em;
  }
}
