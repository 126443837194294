.kioskcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 15%;
  background-color: var(--background-color1);
  z-index: 20;
}

.kioskmain {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 90%;
  background: var(--background-color3);

  border-radius: 10px;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 1;
}

.kioskbanner img {
  width: 100%;
}
.categories {
  display: flex;
  width: 100%;
}

.category1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 30%;
  background: var(--background-color6);
  border-radius: 10px;
  margin: 10px;
}
.category1 h2 {
  border-bottom: var(--border2);
  padding-bottom: 5px;
}

.comicdrop {
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 10px;
  border: 1px solid var(--accent-color);
  border-radius: 2px;
  background: var(--background-color2);
}
.comicdrop:hover {
  background-color: var(--hover-color);
}

.comicdrop.selected {
  background-color: var(--selected-color);
}

.comicdrop img {
  width: 25%;
  height: auto;
  object-fit: contain;
}

.comictitle {
  width: 100%;
  margin: auto;
  justify-content: center;
}
.comictitle h5 {
  font-size: small;
  margin-bottom: 1px;
  margin-top: 2px;
}
.comictitle h3 {
  font-size: large;
}

.mintleft {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: small;
}

.mintleft > div {
  flex: 1;
  text-align: center;
  align-items: center; /* Center the text within each div */
  padding: 2px;
  border-radius: 5px;
  margin: 0 5px; /* Optional: add space between each div */
}

.category2 {
  width: 40%;
  padding: 10px;
}
.kioskseller img {
  width: 100%;
}

.category3 {
  width: 100%;
}
.decriptionwrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: top;
  width: 100%;
  gap: 5px;
}

.descriptionleft {
  width: 50%;
  padding-top: 5px;
  margin-bottom: auto;
}
.variationslider {
  width: 100%;
}
.variationslider img {
  width: 100%;
}
.descriptionright {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  justify-content: center;
  gap: 5px;
}

.mintcomic {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid var(--accent-color3);
  border-radius: 2px;
  padding: 5px;
  margin-top: 10px;
  gap: 20px;
}

.descriptioncomicimg {
  width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.comicdescriptiondesktop {
  width: 100%;
  font-size: smaller;
  border: 2px solid var(--accent-color2);
  border-radius: 5px;
  background: var(--background-color6);
}
.comicdescriptionmobile {
  position: absolute;
  visibility: hidden;
  width: 100%;
  font-size: smaller;
  border: 2px solid var(--accent-color2);
  border-radius: 2px;
  top: 0;
  left: 0;
  z-index: -20;
}

.descriptiontxt {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
  text-align: justify;
  font-size: 1.2em;
}

.descriptiontxt::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.descriptiontxt::-webkit-scrollbar-thumb {
  background-color: #9df6ff; /* Set the color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

.descriptiontxt::-webkit-scrollbar-track {
  background-color: #282c34; /* Set the color of the track */
}

.yourcollection {
  width: 40%;
}
.variations {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.variations img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
}
.variation.gray-hue img {
  filter: grayscale(100%);
}

.comiccount {
  position: absolute;
  background-color: var(--accent-color2);
  padding: 5px;
  top: 1px;
  left: 1px;
}
.progress-container {
  position: relative;
  width: 100%;
  background-color: var(--hover-color);
  border-radius: 2px;
  overflow: hidden;
  margin-top: 10px;
}

.common {
  background-color: #4b4b47;
}

.uncommon {
  color: #282c34;
  background-color: #00ff9f;
}
.rare {
  background-color: #3acbff;
}

.epic {
  background-color: #9844c0;
}

.legendary {
  background-color: #ff091a;
}

.unopened {
  background-color: rgb(214, 24, 24);
}

.progress-bar {
  position: absolute;
  height: 100%;
  background-color: var(--accent-color2);
  text-align: center;
  color: white;
  line-height: 20px;
  transition: width 0.3s ease;
  z-index: 1;
}
.progress-text {
  position: relative;
  z-index: 5;
}

.fully-minted {
  position: relative;
  background-color: #f44336; /* Red color for fully minted */
  color: white; /* White text color */
  width: 100%; /* Full width */
  height: 20px; /* Same height as progress bar */
  line-height: 20px; /* Vertically center the text */
}

.category4 {
  width: 30%;
  background: var(--background-color6);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
.category4 h2 {
  border-bottom: var(--border2);
  padding-bottom: 5px;
}
.readwrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  background: var(--background-color6);
  padding: 5px;
}
.readwrapper h5 {
  border-bottom: var(--border2);
  padding-bottom: 5px;
}

.rating span {
  background-color: purple;
}

.usercomicwrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}

.comicnames {
  font-size: small;
}
.comiclinks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--accent-color2);
  border-radius: 2px;
}

.comiclinks a {
  text-decoration: none;
  color: var(--accent-color);
  margin: 5px;
}
.trinketdesc {
  width: 90%;
  border: 1px solid var(--accent-color);
  padding: 5px;
}

.trinketbanner img {
  width: 100%;
}
.trinketwrapper {
  margin-top: 10px;
  padding: 5px;
}
.trinkets {
  background-color: #ff091a;
}

.usercomics {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.usercomics img {
  width: 100%;
}

.usercomic-item {
  width: calc(100% / 4);
  padding: 2px;
}
.usercomic-item.selected {
  border: 3px solid var(--accent-color);
  border-radius: 5px;
  transition: border 0.3s ease;
}
.usercomicbuttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 5px;
  padding-top: 10px;
  border-top: var(--border2);
}

.buttonpopover {
  position: relative;
}
.popoverstyle {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--accent-color);
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 100;
  white-space: "nowrap";
}

.comic-reveal-wrapper {
  position: fixed;
  top: 0;
  display: block;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color2);
  z-index: 100;
}

.comic-reveal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}

.comic-video {
  width: 28%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.animated-image {
  width: 22%;
  padding-top: 20px;
  height: auto;
  margin-bottom: 20px;
  animation: flyIn 1s ease forwards, pulse 1s ease 1s forwards,
    hover 4s ease-in-out 2s infinite;
  transform-origin: center;
  cursor: pointer;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.9));
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes flyIn {
  0% {
    transform: scale(0.1); /* Start small (far away) */
    opacity: 0; /* Invisible at the start */
    filter: drop-shadow(
      0px 2px 5px rgba(0, 0, 0, 0.1)
    ); /* Small shadow at start */
  }
  100% {
    transform: scale(1); /* Full size */
    opacity: 1; /* Fully visible */
    filter: drop-shadow(
      0px 20px 30px rgba(0, 0, 0, 0.4)
    ); /* Full shadow when it lands */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.4));
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
    filter: drop-shadow(
      0px 25px 35px rgba(0, 0, 0, 0.5)
    ); /* Shadow grows when the image enlarges */
  }
  100% {
    transform: scale(1); /* Back to normal size */
    filter: drop-shadow(
      0px 20px 30px rgba(0, 0, 0, 0.4)
    ); /* Back to the original shadow */
  }
}

@keyframes hover {
  0%,
  100% {
    transform: translateY(0); /* No movement at start and end */
    filter: drop-shadow(
      0px 10px 5px rgba(0, 0, 0, 0.8)
    ); /* Shadow stays the same */
  }
  50% {
    transform: translateY(-20px); /* Moves up slightly */
    filter: drop-shadow(
      0px 20px 10px rgba(0, 0, 0, 0.5)
    ); /* Shadow becomes smaller as the image rises */
  }
}

@media (max-width: 1280px) and (max-height: 800px) {
  .categories {
    flex-direction: column;
    width: 100%;
  }

  .category1 {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .kioskmain {
    max-width: 80%;
  }
  .category2 {
    width: 100%;
    padding: 0px;
  }
  .comicdrop {
    width: 100%;
    gap: 10px;
    padding: 10px;
    font-size: larger;
  }
  .comicdrop img {
    width: 15%;
  }
  .comictitle {
    width: 100%;
  }
  .category4 {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .comicdescriptionmobile {
    position: relative;
    visibility: visible;
    width: 100%;
    font-size: smaller;
    border: 2px solid var(--accent-color2);
    border-radius: 2px;
    z-index: 20;
  }
  .comicdescriptiondesktop {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: -20;
  }
  .descriptiontxt {
    max-height: 250px;
    padding: 5px;
  }
  .animated-image {
    width: 45%;
    height: auto;
  }
  .usercomic-item {
    width: calc(100% / 5);
    padding: 2px;
  }
  .comic-video {
    width: 70%;
  }
  .mintleft {
    justify-content: center;
    gap: 10px;
    font-size: large;
  }
  .comictitle h5 {
    font-size: large;
  }
  .comictitle h3 {
    font-size: larger;
  }
  .comictitle h6 {
    font-size: large;
  }
  .trinketwrapper {
    padding: 0;
  }

  .comicnames {
    font-size: 1.5em;
  }
  .comiclinks a {
    font-size: 1.8em;
  }
}

@media (max-width: 1024px) {
  .categories {
    flex-direction: column;
    width: 100%;
  }

  .category1 {
    width: 100%;
    padding: 0px;
    margin: 0;
  }

  .kioskmain {
    max-width: 100%;
  }
  .category2 {
    width: 100%;
    padding: 0px;
  }
  .comicdrop {
    width: 100%;
    gap: 10px;
    padding: 10px;
    font-size: larger;
  }
  .comicdrop img {
    width: 15%;
  }
  .comictitle {
    width: 100%;
  }
  .category4 {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .comicdescriptionmobile {
    position: relative;
    visibility: visible;
    width: 100%;
    font-size: smaller;
    border: 2px solid var(--accent-color2);
    border-radius: 2px;
    z-index: 20;
  }
  .comicdescriptiondesktop {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: -20;
  }
  .descriptiontxt {
    max-height: 250px;
    padding: 5px;
  }
  .animated-image {
    width: 45%;
    height: auto;
  }
  .usercomic-item {
    width: calc(100% / 5);
    padding: 2px;
  }
  .comic-video {
    width: 70%;
  }
  .mintleft {
    justify-content: center;
    gap: 10px;
    font-size: large;
  }
  .comictitle h5 {
    font-size: large;
  }
  .comictitle h3 {
    font-size: larger;
  }
  .comictitle h6 {
    font-size: large;
  }
  .trinketwrapper {
    padding: 0;
  }

  .comicnames {
    font-size: 1.5em;
  }
  .comiclinks a {
    font-size: 1.8em;
  }
}

@media (max-width: 760px) {
  .categories {
    flex-direction: column;
    width: 100%;
  }

  .category1 {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .kioskmain {
    max-width: 100%;
  }
  .category2 {
    width: 100%;
    padding: 0px;
  }
  .comicdrop {
    width: 100%;
    gap: 20px;
    padding: 10px;
    font-size: smaller;
  }
  .comicdrop img {
    width: 20%;
  }
  .comicdrop h6 {
    font-size: 0.6em;
  }
  .descriptionleft {
    margin-top: 5px;
  }
  .descriptionleft h5 {
    position: absolute;
    visibility: hidden;
  }
  .descriptiontxt {
    max-height: 150px;
    font-size: 1em;
  }
  .category4 {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
  }
  .animated-image {
    width: 70%; /* Set a base size for the image */
    height: auto;
  }
  .usercomic-item {
    width: calc(100% / 4);
    padding: 2px;
    margin-top: 0;
  }
  .comic-video {
    width: 80%;
  }
  .trinkets {
    font-size: smaller;
  }
  .mintleft {
    justify-content: center;
    gap: 10px;
    font-size: small;
  }
  .comictitle h5 {
    font-size: small;
  }
  .comictitle h6 {
    font-size: 1em;
  }
  .comicdescriptionmobile {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: -20;
  }
  .comicdescriptiondesktop {
    position: relative;
    visibility: visible;
    z-index: 20;
  }
  .trinketwrapper {
    padding: 0;
  }

  .comicnames {
    font-size: 0.8em;
  }
  .comiclinks a {
    font-size: 1.2em;
  }

  .mintleft > div {
    margin: 0 0px;
  }
}
