.fixed-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 15%;
  background: var(--background-color3);
  z-index: 20;
}
.collectionmain {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  background: var(--background-color3);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 30px;
  z-index: 1;
}

.collections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: auto;
}

.collection-window {
  width: calc(33.33% - 7px);
  min-width: 350px;
}

.title-utilities {
  margin-top: 5px;
}
.collection-img {
  width: 100%;
  border-radius: 5px;
}
.collection-description {
  background: var(--background-color6);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.collection-details {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-evenly;

  margin-top: 10px;
  border: var(--border3);
  border-radius: 5px;
  padding-top: 5px;
}

.collection-utilities {
  display: flex;
  gap: 10px;
}
.collection-details span {
  color: var(--accent-color);
}

.fc-farm {
  display: flex;
  flex-direction: column;
  border: var(--border2);
  border-radius: 5px;
  width: 20%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
}
.fc-farm h6 {
  margin: 0;
}
.fc-farm div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.fc-farm a {
  color: var(--accent-color2);
}

.upgrades {
  display: flex;
  border: var(--border2);
  border-radius: 5px;
  width: 60%;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 120px;
}
.upgrade-icons {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  gap: 5px;
}

.upgrade-icons img {
  padding: 2px;
  width: 17%;
}

.marvin-icons img {
  width: 90%;
}
.fc-farm img {
  width: 40%;
}
.special-utilities {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  border: var(--border2);
  border-radius: 5px;
  padding: 5px;
  min-height: 120px;
}
.special-utilities h6 {
  color: var(--accent-color2);
  position: absolute;
  top: 5px;
  left: 5px;
}
.special-utilities span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-size: 2em;
  background: var(--background-color5);
}
.special-utilities img {
  width: 30%;
  border-radius: 5px;
}

.collection-items-wrapper {
  margin-top: 10px;
  background: var(--background-color6);
  border-radius: 5px;
  padding-top: 5px;
}

.collection-items {
  width: 100%;
  display: flex;
  gap: 5px;
  padding: 5px;
}
.collection-items img {
  width: 100%;
  max-width: 180px;
}
.collection-links {
  margin-top: 10px;
}
.collection-links img {
  width: 50%;
  border: var(--border3);
  border-radius: 5px;
}

@media (max-width: 1024px) {
  .collectionmain {
    width: 100%;
  }
  .collection-window {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: var(--border1);
  }
}

@media (max-width: 760px) {
  .collectionmain {
    width: 100%;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .collection-window {
    width: 100%;
  }
  .collection-links {
    display: flex;
    width: 100%;
  }
  .collection-links img {
    width: 50%;
    border: var(--border3);
    border-radius: 5px;
  }
  .marvin-icons {
    flex-direction: column;
  }
  .special-utilities span {
    font-size: 1.2em;
  }
  .special-utilities img {
    width: 50%;
    padding-top: 20px;
  }
}
