/* SplitScreenComponent.css */

.limiter {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding-bottom: 50px;
  z-index: 50;
}

.grid-item {
  width: calc(100% / 3);
}

.split-screen-marvin {
  display: block;
  padding: 0 10px;
}

.left-section-marvin-wrap,
.right-section-marvin-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.left-section-marvin,
.right-section-marvin {
  background: radial-gradient(
    circle,
    rgba(10, 19, 28, 0.4) 0%,
    rgba(44, 63, 80, 0.8) 100%
  );
}

.section-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 30px;
}

.section-title--desktop {
  display: none;
  writing-mode: vertical-rl;
  position: absolute;
}

.section-title--left {
  left: -40px;
}

.section-title--right {
  right: -40px;
}

.nomarvin-section-marvin {
  position: relative;
  display: flex;
  justify-content: center;
  background: var(--background-color3);
  border-radius: 10px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  z-index: 1;
}

.left-section-marvin {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 10px;
  background: var(--background-color5);
  border-radius: 5px;
}

.pagination {
  display: inline-block;
}
.marvinSearchWrap .select-field {
  width: 25%;
}

.marvinsearch {
  padding: 10px;
  margin: 10px;
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  color: azure;
  border-radius: 5px;
  background-color: rgb(24, 24, 24);
  text-align: center;
}
.marvinsearch::placeholder {
  color: gray;
}
.marvinsearch:focus {
  border-color: hsl(186, 100%, 81%, 0.7);
}

.marvinshelf {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  padding: 10px;
  background-color: #282c34;
  align-items: stretch;
}

.marvinshelf img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
}
.marvinshelf img:hover {
  cursor: pointer;
  transform: scale(0.95);
}

.marvinshelf .selected {
  border: 3px solid hsl(186, 100%, 81%, 0.7);
  border-radius: 5px;
  transition: border 0.3s ease;
}

.right-section-marvin {
  position: relative;
  width: 100%;
  padding: 10px;
  background: var(--background-color5);
  border-radius: 5px;
  min-height: 200px;
}

.right-section-marvin img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0; /* Remove margin */
}

.undertitle {
  text-align: center;
}

.imagecontainer p {
  text-transform: uppercase;
}

.imagecontainer {
  flex: 1;
  max-width: 100%;
  border: 2px solid rgb(157, 246, 255, 0.3);
  border-top: 5px solid rgb(159, 87, 218);
  box-shadow: 0px 10px 0px 0px rgb(159, 87, 218);
  border-radius: 5px;
  font-size: 150%;
}

.details {
  position: relative;
}

.traits {
  width: 100%;
  height: auto;
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  border-top: 3px solid rgb(159, 87, 218);
  box-shadow: 0px -10px 0px 0px rgb(159, 87, 218);
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 15px;
}

.marvinswiper {
  width: 100%;
  margin-bottom: 25px;
}

.details img {
  width: 15%;
  min-width: 15px;
  margin: 2px;
}

.downloads {
  position: relative;
  border: 2px solid hsl(186, 100%, 81%, 0.3);
  border-bottom: 3px solid rgb(159, 87, 218);
  box-shadow: 0px 10px 0px 0px rgb(159, 87, 218);
  border-radius: 5px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 15px;
}
.downloads button {
  margin-block: 5px;
}

.printaf {
  height: 4175px;
  width: 2953px;
  padding-top: 75px;
  display: flex;
  justify-content: center;

  background-color: #282c34;
}

.bigimage {
  font-size: 0;
}
.bigimage img {
  width: 100%;
  max-width: 2800px;
  height: auto;
}
.bigtraits {
  position: absolute;
  top: 3000px;
  left: 75px;
  width: 100%;
  max-width: 2800px;
}

.paginationButtonsWrap {
  display: flex;
  gap: 5px;
}

@media only screen and (min-width: 600px) {
  .grid-item {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }
  .limiter {
    width: 100%;
  }
  .details img {
    width: 8%;
  }
}

@media only screen and (min-width: 1024px) {
  .split-screen-marvin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
  }

  .left-section-marvin-wrap {
    padding: 0 10px;
    width: 35%;
  }

  .right-section-marvin-wrap {
    padding: 0 10px;
    width: 65%;
  }

  .marvinshelf .marvinSingle {
    width: calc(100% / 3);
    max-width: calc(100% / 3);
  }

  .limiter {
    padding: 0 0px;
    width: 100%;
  }
  .details img {
    width: 5%;
  }
  .grid-item {
    width: calc(100% / 2);
    max-width: calc(100% / 2);
  }
}

@media only screen and (min-width: 1280px) {
  .undertitle {
    display: flex;
  }

  .details {
    width: 220px;
    padding: 10px 0 0 10px;
  }

  .marvinswiper {
    width: calc(100% - 220px);
  }
  .limiter {
    padding: 0 20px;
    width: 100%;
  }
  .details img {
    width: 20%;
  }
  .grid-item {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }
}

@media only screen and (min-width: 1600px) {
  .grid-item {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }

  .MarvinContainer-innerWrap {
    padding: 0 75px;
  }

  .section-title--desktop {
    display: block;
  }

  .section-title--mobile {
    display: none;
  }
  .limiter {
    width: 90%;
  }
}

@media only screen and (min-width: 1600px) {
  .undertitle {
    display: flex;
  }
}

@media only screen and (min-width: 1920px) {
  .MarvinContainer-innerWrap {
    max-width: 90%;
    margin: 0 auto;
  }

  .left-section-marvin-wrap,
  .right-section-marvin-wrap {
    margin: 0;
  }
}

@media only screen and (min-width: 2560px) {
  .details {
    width: 180px;
  }

  .marvinswiper {
    width: calc(100% - 180px);
  }
  .limiter {
    width: 80%;
  }
}
