.cube-container {
  width: 100px;
  height: 100px;
  perspective: 800px;
  margin: 50px auto;
}
.cube {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: rotate 10s infinite linear;
}

.face {
  position: absolute;
  width: 100px;
  height: 100px;
  color: rgb(191, 222, 236);
  text-shadow: 0 0 10px #d0ebff, 0 0 15px #0074cc;
  font-size: 30px;
  text-align: center;
  line-height: 45px;
  font-weight: 700;
  background: transparent;
  opacity: 0.9;
  border: 2px solid;
  border-image: linear-gradient(
    90deg,
    rgba(14, 8, 110, 1) 0%,
    rgba(15, 15, 167, 1) 26%,
    rgba(20, 45, 255, 1) 85%
  );
  box-shadow: 0 0 50px rgba(8, 178, 245, 0.61);
}

.front {
  transform: translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.back {
  transform: rotateY(180deg) translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.right {
  transform: rotateY(90deg) translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.left {
  transform: rotateY(-90deg) translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.top {
  transform: rotateX(90deg) translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.bottom {
  transform: rotateX(-90deg) translateZ(50px);
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.cube-container:hover .cube {
  animation-play-state: paused;
}

@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }

  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}
