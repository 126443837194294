.opengift {
  position: fixed;
  display: block;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color2);
  padding-top: 2px;
  z-index: 9999;
}

.opengift h3 span {
  color: var(--accent-color2);
  text-transform: uppercase;
}

.rarity-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 30%;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.rarity-container > div {
  flex: 1;
  text-align: center;
  align-items: center; /* Center the text within each div */
  padding: 2px;
  border-radius: 5px;
  margin: 0 5px; /* Optional: add space between each div */
}

/* Base styles */
.active {
  position: relative; /* Needed for the pseudo-elements */
  font-size: large;

  /* Ensure the glow effect doesn't overflow the box */
}

.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -3px;
  border: 3px solid transparent; /* Initial border */
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5); /* Initial glow */
  animation: glowingBorder 2s linear infinite; /* Animation */
}

@keyframes glowingBorder {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.7),
      0 0 5px rgba(255, 255, 255, 0.5);
  }
  25% {
    border-color: var(--accent-color); /* Change color for glowing effect */
    box-shadow: 0 0 5px var(--accent-color), 0 0 10px rgba(0, 255, 255, 0.5);
  }
  50% {
    border-color: var(--accent-color);
    box-shadow: 0 0 5px var(--accent-color), 0 0 10px rgba(255, 0, 255, 0.5);
  }
  75% {
    border-color: var(--accent-color2);
    box-shadow: 0 0 5px var(--accent-color2), 0 0 10px rgba(255, 255, 0, 0.5);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.7),
      0 0 5px rgba(255, 255, 255, 0.5);
  }
}

.nft-description span {
  color: var(--accent-color2);
}

.wallet-wrapper img {
  width: 20%;
}
.wallet-wrapper {
  position: relative;
  padding: 5px;
  width: 100%;
  overflow-wrap: break-word;
  width: 100%;
  border: 2px solid var(--accent-color);
  border-radius: 2px;
}
.address-wrapper h5 {
  color: var(--accent-color2);
}
.address-wrapper p {
  font-size: normal;
  margin-bottom: auto;
}
.address-wrapper span {
  color: var(--accent-color);
}

.key-wrapper {
  position: relative;
  padding: 5px;
  width: 100%;
  margin-top: 5px;

  border: 2px solid var(--accent-color);
  border-radius: 2px;
}

.key-wrapper h5 {
  color: var(--accent-color2);
}
.key-wrapper p {
  font-size: normal;
}
.key-wrapper span {
  color: var(--accent-color);
}

.codes {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}
.secret-key {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  width: 50%;
  padding: 10px;
  border: 1px solid var(--accent-color2);
}

.blurred {
  padding: 2px;
  filter: blur(4px); /* Adjust blur amount as needed */
  transition: filter 0.3s ease; /* Smooth transition */
}

.unblur-txt {
  font-size: small;
}

.qrcode {
  width: 50%;
}
@media (max-width: 1024px) {
  .address-wrapper p {
    font-size: normal;
  }
  .key-wrapper p {
    font-size: normal;
  }
  .key-txt {
    font-size: 1em;
  }
  .qrcode img {
    max-width: 100%;
  }
}

@media (max-width: 760px) {
  .address-wrapper p {
    font-size: small;
  }
  .key-wrapper p {
    font-size: small;
  }
  .key-txt {
    font-size: 0.6em;
  }
  .qrcode img {
    max-width: 80%;
  }
}
