.trinket-container {
  display: flex;
  align-items: top;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 15%;
  background-color: var(--background-color1);
  z-index: 20;
}
.trinketmain {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60%;
  background: var(--background-color3);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 20px;
  gap: 10px;
}

.myjunk-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  background: var(--background-color3);
  border: var(--border1);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 20px;
  gap: 10px;
}
.trinketmain .swiper-wrapper {
  display: flex;
  gap: 5px;
}

.trinketmain h3,
.trinketmain h6 {
  position: relative;
  align-self: start;
  margin: 0px;
}
.trinketmain h3 {
  color: var(--accent-color);
}
.trinketslider {
  position: relative;
  height: auto;
  border-bottom: solid 1px var(--accent-color);
}

.trinketslider::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(25, 8, 32, 1) 100%
  );
  pointer-events: none;
  z-index: 10;
}

.trinket-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  min-height: 300px;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 10px;
  gap: 2px;
  margin-bottom: 5px;
  background-color: var(--background-color6);
  border-radius: 5px;
}
.trinket-slide:hover {
  background-color: var(--hover-color);
  border-radius: 10px;
}

.trinket-slide img {
  width: 100%;
  padding: 3%;
  border-radius: 10px;
  filter: grayscale(0.8);
}

.trinket-slide-junk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-color6);
  border-radius: 5px;
  max-width: 150px;
  min-height: 170px;
  cursor: pointer;
  transition: 0.1s ease;
  padding: 10px;
}

.trinket-slide-junk h5 {
  font-size: 100%;
}

.trinket-slide-junk:hover {
  background-color: var(--hover-color);
  border-radius: 10px;
}
.trinket-slide-junk img {
  width: 100%;
  padding: 5%;
  filter: grayscale(0.8);
}

.trinket-slide h5 {
  color: var(--accent-color2);
}

.trinket-slide h6 {
  font-size: 0.9em;
  color: darkgray;
}
.supply-zero {
  color: grey;
}
.supply-zero img {
  filter: grayscale(1);
}

.supply-zero h5 {
  color: grey;
}

.trinket-remaining {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: var(--border1);
}
.trinket-claimed {
  font-size: 0.9em;
  color: var(--accent-color2);
}
.trinket-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 30px; /* Ensure visible height */
  background-color: var(
    --hover-color
  ); /* Background color of the progress bar */
  border-radius: 2px;
  overflow: hidden;
  margin: auto;
  font-size: 0.9em;
}

.trinket-progress h6 {
  z-index: 10;
  margin: auto;
}

.trinket-progress.claimed {
  color: var(--accent-color2);
  align-content: center;
}

.trinket-progress-bar-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0; /* Fill the height of the container */
}

.trinket-progress-bar {
  height: 100%; /* Ensure the bar fills the container height */
  background-color: var(--accent-color2); /* Color of the progress bar */
  transition: width 0.3s ease; /* Smooth transition when width changes */
}

.eligible img {
  filter: grayscale(0);
}

.claimed img {
  filter: grayscale(0);
}

.progress-full {
  position: relative; /* Needed for the pseudo-elements */
  font-size: large;

  /* Ensure the glow effect doesn't overflow the box */
}

.eligible::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 1px;
  border: 3px solid transparent; /* Initial border */
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5); /* Initial glow */
  animation: glowingBorder 2s linear infinite; /* Animation */
}

@keyframes glowingBorder {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.7),
      0 0 5px rgba(255, 255, 255, 0.5);
  }
  25% {
    border-color: var(--accent-color); /* Change color for glowing effect */
    box-shadow: 0 0 5px var(--accent-color), 0 0 10px rgba(0, 255, 255, 0.5);
  }
  50% {
    border-color: var(--accent-color);
    box-shadow: 0 0 5px var(--accent-color), 0 0 10px rgba(255, 0, 255, 0.5);
  }
  75% {
    border-color: var(--accent-color2);
    box-shadow: 0 0 5px var(--accent-color2), 0 0 10px rgba(255, 255, 0, 0.5);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.7),
      0 0 5px rgba(255, 255, 255, 0.5);
  }
}

.placeholderimg {
  width: 100%;
}

@media (max-width: 1024px) {
  .trinketmain {
    width: 100%;
    padding: 10px;
  }
  .trinketmain h3 {
    font-size: 1.5em;
  }
  .trinketmain h6 {
    font-weight: 400;
  }
  .trinketmain h3,
  .trinketmain h6 {
    margin: -5px;
    padding-left: 10px;
  }
  .trinket-slide {
    max-width: 220px;
    min-height: 150px;
    padding: 10px;
  }
  .trinket-slide-junk {
    max-width: 150px;
    min-height: 150px;
    padding: 5px;
  }

  .trinket-slide-junk h5 {
    font-size: 1em;
  }
  .trinket-progress.claimed {
    font-size: 1.1em;
  }
  .trinket-slide h4 {
    font-size: 0.8em;
  }
  .trinket-slide h6 {
    font-size: 0.6em;
    font-weight: 300;
    color: darkgray;
  }
  .trinket-remaining {
    font-size: 0.7em;
  }
  .trinket-progress h6 {
    margin: auto;
    padding-left: 0px;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .trinketmain {
    width: 100%;
    padding: 15px;
    row-gap: 5px;
  }
  .myjunk-wrapper {
    padding: 10px;
    padding-bottom: 10px;
    gap: 2px;
  }

  .trinket-slide {
    max-width: 250px;
    min-height: inherit;
    padding: 5px;
  }

  .trinket-slide h5 {
    font-size: 1em;
  }
  .trinket-slide h6 {
    font-size: 0.7em;
    font-weight: 300;
    color: darkgray;
  }

  .trinket-slide-junk {
    max-width: 150px;
    min-height: 120px;
    padding: 5px;
  }

  .trinket-slide-junk h5 {
    font-size: 0.7em;
    font-weight: 300;
  }

  .trinket-remaining {
    font-size: 0.7em;
  }
  .trinketmain h3,
  .trinketmain h6 {
    margin: 0px;
    padding-left: 0px;
  }

  .trinket-progress h6 {
    margin: auto;
  }

  .trinketmain h6 {
    font-size: 0.7em;
    font-weight: 400;
  }
  .trinketmain h3 {
    font-size: 1em;
  }
  .trinket-progress {
    height: 20px; /* Ensure visible height */
  }
}
