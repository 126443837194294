.readerwrapper {
  position: absolute;
  width: 100%;
  background-color: black;
  z-index: 50;
  margin: auto;
  display: flex;
  justify-content: center;
}

.reader {
  position: relative;
  width: 80%;
  display: flex;
  margin-top: 2%;
  margin-bottom: 10%;
}

.webreader {
  position: relative;
  width: 70%;
  display: flex;
  margin-top: 2%;
  margin-bottom: 10%;
}

.reader img {
  width: 50%;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.webreader img {
  width: 100%;
}

.backtokiosk {
  position: absolute;
  top: 10px;
  left: 10px;
}

.nocomic {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.558);
}

.thankyou {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 75%;
  top: 50%;
  text-align: center;
}
.webnocomic {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 10px;
}

.backtotop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.3s ease;
}

@media (max-width: 1440px) {
  .reader {
    width: 85%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    margin-bottom: 15%;
  }
  .reader img {
    width: 50%;
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
}

@media (max-width: 1024px) {
  .reader {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    margin-bottom: 18%;
  }
  .reader img {
    width: 100%;
    border-left: none;
    border-right: none;
  }

  .nocomic {
    position: relative;
    transform: translate(0%, 0%);
    left: 0;
    top: 0;
    text-align: center;
    margin-top: 10px;
  }
  .webnocomic {
    position: relative;
    transform: translate(0%, 0%);
    left: 0;
    bottom: 0;
    text-align: center;
  }
  .webreader {
    width: 100%;
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 10%;
  }
}

@media (max-width: 420px) {
  .reader {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .thankyou {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    text-align: center;
  }
}
