.unitname {
  text-align: left;
  justify-content: left;
  text-transform: uppercase;
  font-size: 6em;
  padding-left: 20px;
  border: 5px solid white;
  border-radius: 20px;
  max-width: 1620px;
  margin-left: 30px;
  margin-bottom: 50px;
}

.unitname p {
  margin-top: -20px;
}
.unitname h3 {
  font-size: 1.8em;
}

.unitdata {
  position: absolute;
  right: 40px;
  top: 0;
  font-size: 5em;
  border: 5px solid white;
  border-radius: 20px;
  max-width: 800px;
  padding: 20px;
}

.QRCodeContainer {
  position: absolute;
  right: 40px;
  bottom: 20px;
  background-color: white;
  padding: 40px;
}

.bigtraitcontainer {
  text-transform: uppercase;
  font-size: 3.7em;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto a new row when needed */
  max-width: 2000px; /* Set your specific width */
  left: 30px;
}
.bigtraitbox {
  justify-content: center;
  display: flex;
  border: 5px solid white;
  border-radius: 20px;
  padding: 10px;
  margin: 20px;
}

.bigtraitcontainer span {
  margin-left: 10px;
  margin-right: 10px;
}
