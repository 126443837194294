/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2%;
}

.trinket-modal-content {
  display: inline;
  background-color: var(--background-color2);
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  text-align: center;
  position: relative;
}

.trinket-modal-content img {
  width: 90%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.trinket-progress-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}
.trinket-reward {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: var(--border1);
  border-radius: 2px;
  margin: 0;
  gap: 10px;
  padding: 10px;
}
.trinket-reward h6 {
  margin: 0;
  padding: 0;
}

.trinket-modal-content h5 {
  pointer-events: none;
}
.trinket-modal-content h5 span {
  color: var(--accent-color);
}
.trinket-reward img {
  max-width: 50px;
  margin: auto;
}
.trinket-reward-assets {
  position: relative;
  display: flex;
  gap: 15px;
  padding: 5px;
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .trinket-modal-content {
    width: 80%;
  }

  .trinket-modal-content img {
    width: 90%;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .trinket-modal-content h5 {
    font-size: 1em;
  }
  .trinket-reward h6 {
    font-size: 0.8em;
  }
  .trinket-reward img {
    max-width: 40px;
  }
}
