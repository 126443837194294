.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  padding: 10px;
  border: var(--border2);
  border-radius: 5px;
  background-color: var(--background-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-asset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.selected-asset img {
  width: 15%;
}

.dropdown-options {
  position: absolute;
  width: 100%;
  border: var(--border2);
  background-color: var(--background-color);
  max-height: 400px;
  overflow-y: auto;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9df6ff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #282c34;
  }
}

.dropdown-option {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--hover-color);
}

.dropdown-option img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
