.swappermain {
  position: relative;
  display: flex;
  justify-content: center;
  background: var(--background-color3);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: center;
  height: auto;
  align-self: stretch;
  z-index: 1;
  margin: auto;
}

.arrows-down {
  position: relative;
  display: flex;
  width: 65%;
  align-content: center;
  justify-content: space-between;
  color: var(--accent-color);
  border-top: solid 3px var(--accent-color);
  box-shadow: 0px -8px 10px -5px var(--accent-color);
  font-size: 2em;
  text-align: center;
  margin: auto;
  margin-bottom: -1px;
  margin-top: 10px;
}
.arrows-up {
  display: none;
}
.arrows-down svg {
  position: relative;
  top: -1px;
}
.arrows-down svg:first-child {
  margin-left: -10px;
}
.arrows-down svg:last-child {
  margin-right: -10px;
}
.swappermain h2 {
  writing-mode: vertical-rl;
  position: absolute;
  left: -50px;
  margin-top: 10px;
}

.swappermain p {
  padding-top: 10px;
}

.incompatible {
  border: 2px solid red;
}

.trait-item.incompatible {
  pointer-events: none;
}

.trait-image.incompatible {
  position: relative;
}

.trait-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
}

.swapper-accordion .accordion-body {
  padding: 0px;
}

.swapperimage img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}

.swapperimage-dynamic {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
}
.swapperimage-dynamic img {
  width: 100%;
  height: auto;
}

.maincontainer {
  display: flex;
  gap: 5px;
}

.maincontainer p {
  padding-top: 0;
}

.Selectfield {
  border-radius: 5px;
  max-width: 610px;
  margin-inline: 10px;
  padding: 10px;
  background-color: var(--background-color6);
  margin-bottom: auto;
}

.selecttraits {
  position: relative;
  border: var(--border3);
  border-radius: 5px;
  min-width: 150px;
  max-height: 620px;
}
.selecttraits p {
  margin: auto;
  background-color: var(--accent-color2);
}
.listitems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 5px;
  margin: auto;
}

.trait-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  background-color: var(--background-color6);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.trait-label:hover {
  background-color: rgb(157, 246, 255, 0.25);
}

.trait-label.selected {
  background-color: rgb(157, 246, 255, 0.8);
}

.trait-name {
  margin-top: 2px;
  font-size: 80%;
}

.trait-label img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.swapcost {
  position: relative;
  background-color: var(--background-color6);
  border-radius: 5px;
  max-width: 400px;
  max-height: 300px;
  margin: 20px auto 0;
  padding: 1%;
}

.showcase-description {
  border: var(--border3);
  border-radius: 5px;
  padding: 5px;
  margin: auto;
}

.swapcost .marketwrapper {
  display: flex;
  align-items: center;
  border-top: var(--border2);
  padding: 5px;
  margin: auto;
}

.swapcost .marketwrapper img {
  width: 80%;
  border: var(--border1);
  border-radius: 5px;
}

.swapcost .marketwrapper h6 {
  display: inline;
}
.showcase-description {
  border: var(--border3);
  border-radius: 5px;
  padding: 5px;
  margin: auto;
}
.costmobile .not-connected-button {
  display: flex;
  width: 40%;
  margin: 0;
}
.priceshow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120%;
  width: 80%;
}

.priceshow .fccube img {
  max-width: 30%;
  height: auto;
}

.marketwrapper {
  display: flex;
  justify-content: center;
}

.marketlink img {
  display: block;
  margin: 0 auto;
  max-width: 100px;
}
.nowalletswapper {
  padding: 20px;
  max-width: 90%;
}

.swapdescription {
  width: 90%;
  font-size: 0.9em;
  margin: auto;
  margin-top: 10px;
  border-top: var(--border1);
}
.component-container {
  position: relative;
  z-index: 1;
}
.marvinoverlay {
  position: absolute;
}

@media only screen and (max-width: 1024px) {
  .maincontainer {
    flex-wrap: wrap;
    padding: 0px;
    gap: 10px;
  }
  .Selectfield {
    width: calc(50% - 10px);
    padding: 5px;
    margin-inline: 0;
  }
  .middlsection {
    order: 3;
    width: 100%;
  }
  .listitems {
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    padding: 5px;
  }
  .swappermain {
    margin-bottom: 0px;
  }
  .arrows-mobile {
    display: none;
  }
  .arrows-up {
    display: flex;
    width: 50%;
    order: 3;
    border-top: none;
    border-bottom: solid 3px var(--accent-color);
    margin-bottom: 20px;
    font-size: 1.5em;
    box-shadow: 0px 8px 10px -5px var(--accent-color);
    margin-top: -15px;
    margin-bottom: auto;
  }
  .arrows-up svg:nth-child(2) {
    top: 30px;
  }
  .arrows-up svg {
    top: 1px;
  }
}

@media only screen and (max-width: 768px) {
  .maincontainer {
    flex-wrap: wrap;
    padding: 0px;
    gap: 2px;
  }

  .Selectfield {
    width: calc(50% - 4px);
    padding: 2px;
    margin-inline: -5px;
  }
  .middlsection {
    order: 3;
    width: 95%;
  }
  .swapdescription {
    font-size: 0.7em;
  }

  .arrows-mobile {
    display: none;
  }
  .arrows-up {
    display: flex;
    width: 50%;
    order: 3;
    border-top: none;
    border-bottom: solid 3px var(--accent-color);
    box-shadow: 0px 8px 10px -5px var(--accent-color);
    margin-top: -15px;
    margin-bottom: auto;
  }
  .arrows-up svg {
    top: 1px;
  }
  .swapcost {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .swapcost h6 {
    display: none;
  }
  .swap-two-steps {
    font-size: small;
  }
  .swapcost .marketwrapper h6 {
    font-size: 0.8em;
  }
  .priceshow {
    width: 70%;
  }
}
