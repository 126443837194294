
// Your Bootstrap customizations
$offcanvas-bg-color: rgb(25, 21, 28);
$offcanvas-border-width:2px;
$offcanvas-border-color:rgb(157, 246, 255, 0.784);
$offcanvas-color: hsl(187, 24%, 93%);
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: 50vh;
$btn-close-width:   2em;
$btn-close-color:    hsl(187, 24%, 93%);     
$btn-close-position-x: 90%;
$btn-white-space:null;
$btn-border-color: rgb(157, 246, 255, 0.3);
$progress-bar-bg: #9B7BCF;
$progress-bg: hsl(187, 24%, 93%);
$accordion-color:  hsl(187, 24%, 93%); 
$accordion-bg:  rgb(19,19,19);
$accordion-button-color: hsl(187, 24%, 93%); 
$accordion-icon-color:   #9B7BCF;
$accordion-button-active-bg: rgb(157, 246, 255);
$accordion-border-color: rgb(19,19,19);
$accordion-button-focus-box-shadow: #9B7BCF;
$btn-hover-bg-tint-amount: 35%;
$btn-hover-border-tint-amount:35%;
$modal-content-bg:rgb(45,56,83);    
$modal-content-border-color: rgb(157, 246, 255, 0.784);
$modal-header-border-color:  rgb(157, 246, 255, 0.784);
$modal-footer-border-color:  rgb(157, 246, 255, 0.784);
$modal-content-border-width: 2px;
$modal-content-border-radius:5px;   
.cubeoffcanvas
  {
    height: 100vh !important// Adjust the value as needed
  }

// Adjust vertical height for mobile devices

.footercanvas 
  {height: 40vh !important}



@media only screen and (max-width: 1024px) {
  .offcanvas {
    height: 90vh !important// Adjust the value as needed
  }
  .cubeoffcanvas
  {
    height: 100vh !important// Adjust the value as needed
  }
  .footercanvas 
  {height: 50vh !important}
 
}

@media only screen and (min-width: 1920px) {
  .offcanvas {
    height: 30vh !important// Adjust the value as needed
  }
  .cubeoffcanvas
  {
    height: 100vh !important// Adjust the value as needed
  }
  .footercanvas 
  {height: 20vh !important}
}
body .btn-dark {

  min-width: 100px;
  min-height: 111px;
}


body .btn-info{
  border-radius: 2px
  
}


body .btn-info:hover{
  box-shadow: 0px 0px 15px 0px rgb(157, 246, 255, 1);
  color: white
  
}

body .btn-info.active{
  box-shadow: 0px 0px 15px 0px rgb(157, 246, 255, 1);
  color: white 
  
}


@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
